import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import { convertDateStringToHumanReadableMonth } from 'utils/date';

const HomeValueAsOf = ({ date, className }) => {
  return (
    date && (
      <div className={twMerge(cx('text-sm text-neutral-dark-75', className))}>
        Updated {convertDateStringToHumanReadableMonth(date)}
      </div>
    )
  );
};

export default HomeValueAsOf;
