import { Button } from '@hometap/htco-components';
import { motion } from 'framer-motion';

import './ApplicationFormWrapper.scss';
import useApplicationNavContext from 'apps/application/hooks/useApplicationNavContext';

const getButtonText = (buttonText, isSavePage) => {
  return buttonText || (isSavePage ? 'Save' : 'Continue');
};

const ApplicationFormWrapper = ({
  children,
  onProgressButtonClick,
  buttonText = '',
  isSavePage = false,
  buttonDisabled = false,
  isLoading = false,
  buttonReplace,
}) => {
  const { navigateToNextPage } = useApplicationNavContext();

  const handleProgress = () => {
    if (buttonDisabled || isLoading) return;
    if (onProgressButtonClick) {
      return onProgressButtonClick();
    }
    navigateToNextPage();
  };

  const buttonReplaceContent = typeof buttonReplace === 'function' ? buttonReplace() : buttonReplace;
  const computedButtonText = getButtonText(buttonText, isSavePage);

  return (
    <div>
      {children}
      <div className="ApplicationFormWrapperButtonContainer">
        {buttonReplaceContent ? (
          buttonReplaceContent
        ) : (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ ease: 'easeInOut' }}>
            <Button
              id="application-form-continue-button" // This is the ID that is being checked in the useGlia.js file
              className="sm_cobrowsing_disabled_field"
              loading={isLoading}
              disabled={buttonDisabled}
              onClick={handleProgress}
              aria-label={computedButtonText}
            >
              {computedButtonText}
            </Button>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default ApplicationFormWrapper;
