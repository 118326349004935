import { useCallback, useEffect, useState } from 'react';
import { PROPERTY_STORAGE_KEY } from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';
import sentry from 'utils/sentry';

// These are keys that need to be removed upon logout as they contain user data
const PROPERTY_STORAGE_KEY_WITH_PREFIX = `htco${PROPERTY_STORAGE_KEY}`;
export const userSessionStorageKeys = [PROPERTY_STORAGE_KEY_WITH_PREFIX];

export const useSessionStorage = (key, initialValue) => {
  const keyWithPrefix = `htco${key}`;
  // key is used to index value in session storage

  useEffect(() => {
    // Get from session storage by keyWithPrefix
    const item = window.sessionStorage.getItem(keyWithPrefix);
    // Parse stored json or if none use initialValue to update value when requested storage key changes
    setStoredValue(item && item !== 'undefined' ? JSON.parse(item) : initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyWithPrefix, window.sessionStorage.getItem(keyWithPrefix)]);

  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from session storage by keyWithPrefix
      const item = window.sessionStorage.getItem(keyWithPrefix);
      // Parse stored json or if none return initialValue
      return item && item !== 'undefined' ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      sentry.logErrorWrapper('Failed to use session storage', error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that persists the new value to sessionStorage.
  const setValueCallback = useCallback(
    value => {
      try {
        // Allow value to be a function to mimic useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to session storage
        window.sessionStorage.setItem(keyWithPrefix, JSON.stringify(valueToStore));
      } catch (error) {
        console.log(error);
      }
    },
    [keyWithPrefix, storedValue],
  );

  return [storedValue, setValueCallback];
};
