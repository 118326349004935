import cx from 'classnames';
import CardWrapper from 'components/CardWrapper';
import TrackDetailCardMainContent from 'components/TrackDetailsCard/TrackDetailsCardMainContent';
import HomeValKeyValue from './HomeValKeyValue';

import HomeValueDisclaimer from './HomeValueDisclaimer';

import HomeValueAsOf from './HomeValueAsOf';
import { getCurrentStageConfig } from 'apps/track-details/utils/helpers';
import { getHomeValuationDisclaimer } from 'utils/home';

const OverviewTrackDetailsCard = ({ track, homeValuation }) => {
  const disclaimer = getHomeValuationDisclaimer({ hasTracks: true, homeValuation }).short;
  const { canShowProgressStepper } = getCurrentStageConfig(track);

  return (
    <CardWrapper>
      <div className={cx({ 'mb-6 md:mb-8': canShowProgressStepper })}>
        <div className="mb-2 flex flex-wrap items-center justify-between gap-1">
          <HomeValKeyValue homeValue={homeValuation.value} />
          <HomeValueAsOf date={homeValuation.as_of_date} />
        </div>
        <HomeValueDisclaimer disclaimer={disclaimer} hasTracks />
      </div>

      {!canShowProgressStepper && <hr className="my-6" />}

      <TrackDetailCardMainContent track={track} />
    </CardWrapper>
  );
};

export default OverviewTrackDetailsCard;
