import { getCurrentUIStageData } from './uiStageModel';
import useInvestmentStatementList from 'apps/quarterly-statement/hooks/useInvestmentStatementList';
import useTrackInvestment from 'hooks/useTrackInvestment';
import useTrackDocuments from 'apps/documents/hooks/useTrackDocuments';

const useTrackDetailsCardData = track => {
  const { config } = getCurrentUIStageData(track);
  const { statements } = useInvestmentStatementList({ track, investmentId: track.active_investment_id });
  const { data: investment } = useTrackInvestment({ investmentId: track.active_investment_id });
  const { hasUnreadDocs } = useTrackDocuments({ trackId: track?.id });
  return config.getCardData({ track, investmentSummary: investment, statements, hasUnreadDocs });
};

export default useTrackDetailsCardData;
