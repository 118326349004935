import React from 'react';
import { Icon, Tooltip } from '@hometap/htco-components';

const KeyValue = ({
  keyName,
  separator = ':',
  value,
  valueClassName,
  keyClassName,
  className,
  testId,
  noValueDisplay = '--',
  toolTipContent,
  toolTipIcon,
  toolTipClassName,
}) => {
  return (
    <div className={className} data-testid={testId}>
      <span className={keyClassName}>
        {keyName}
        {separator}
      </span>{' '}
      <span className={valueClassName}>{value ? value : noValueDisplay}</span>
      {toolTipContent && (
        <Tooltip className={toolTipClassName} content={toolTipContent}>
          <Icon name={toolTipIcon} />
        </Tooltip>
      )}
    </div>
  );
};

export default KeyValue;
