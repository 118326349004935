import React from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { Button } from '@hometap/htco-components';
import './InquiryLocationReengagement.scss';

const InquiryLocationReengagement = () => {
  const { user } = useCurrentUser();
  return (
    <div className="InquiryLocationReengagement">
      <h1 className="DQHeader" data-testid="dq-header">
        We'll be in touch!
      </h1>
      <p className="InquiryDQBody" data-testid="inquiry-dq-row-1">
        Thanks for checking us out — we’ll let you know as soon as we’re ready to help you access your equity.
      </p>
      <div className="InquiryLocationReengagementButtons">
        <Button href={user ? '/dashboard' : 'https://hometap.com/'}>Go home</Button>
      </div>
    </div>
  );
};

export default InquiryLocationReengagement;
