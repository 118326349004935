import React from 'react';

const InquiryUndeterminedOutcome = ({ isAdditionalTrack }) => {
  return (
    <>
      <div className="InquiryOutcomeEstimateUpToAmountWrapper">
        <h1>We need a bit more info to get your estimate</h1>
      </div>
      <div className="InquiryOutcomeEstimateSubTextContainer">
        {isAdditionalTrack ? (
          <>
            Over the next few days, your dedicated Investment Manager will reach out with more details. In the meantime,
            finish your Investment application — it only takes 5 minutes, and we’ll have all the info we need to
            estimate how much equity you can access.
            <div className="InquiryOutcomeEstimateSubText">
              Not sure, yet? Your Investment Manager (and our tools) will help you compare financing options to find one
              that fits your needs.
            </div>
          </>
        ) : (
          <>
            Create an account to finish your Investment application — it only takes 5 minutes, and we’ll have all the
            info we need to estimate how much equity you can access.
            <div className="InquiryOutcomeEstimateSubText">
              Not sure, yet? Your Investment Manager (and our tools) will help you compare financing options to find one
              that fits your needs.
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default InquiryUndeterminedOutcome;
