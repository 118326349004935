import { Button } from '@hometap/htco-components';
import { buildHomeProfileUrl } from 'utils/links';
import Banner from 'components/Banner/Banner';

const CompleteProfileBanner = () => {
  return (
    <>
      <div className="mt-7 border border-t border-solid border-neutral-light-100 xs:mt-0 xs:border-none" />
      <Banner className="mt-7 gap-4 border-none bg-blue-5 p-6 xs:flex-col xs:items-start sm:flex-row">
        <div className="flex w-full flex-col">
          <span className="text-base font-bold sm:text-lg">Unlock your financial snapshot</span>
          <span className="text-sm sm:text-base">
            Finish your home profile to unlock this snapshot — and many other features
          </span>
        </div>
        <Button className="m-0 w-full xs:w-fit" href={buildHomeProfileUrl()}>
          Finish home profile
        </Button>
      </Banner>
    </>
  );
};

export default CompleteProfileBanner;
