const questionsForContractor = `<p>As a homeowner, hiring a contractor for renovations or repairs can often be fraught with delays, disappointments, and false starts. And in some cases, illegitimate contractors can perform shoddy work that you're left to fix, or even abandon projects without warning. It's been reported that in the last 14 years, Americans have lost <a href="https://www.inspectionsupport.com/home-improvement-scams-trends-report-2022/">a total of $206.9 million</a> as a result of home improvement scams, with roof-related projects being the most common at 15% of reported cases.</p>
<p>Here are the most important questions to ask a contractor before bringing them onboard to handle your next home improvement project to ensure that your renovation is done correctly — and to your satisfaction.</p>
<h2>1. Can you provide copies of your license and insurance certificates?</h2>
<p><strong>Why to ask it</strong>: Requesting this documentation protects you in case of accidents or poor workmanship.</p>
<p>Of course, we want to believe that everyone we encounter is sincere and trustworthy. However, the reality is that there are individuals who take advantage of homeowners or claim to have credentials they don't actually possess in order to take the money and run. As you're deciding between your options, make sure you ask for proof of each potential contractor's license and insurance certificates for peace of mind.</p>
<h2>2. How long have you been in business? Can you provide references from past clients, or photos of previous projects?</h2>
<p><strong>Why to ask it:</strong> Hiring a contractor who has a good track record — especially with your chosen renovation type — is important.</p>
<p>One of the best ways to vet a contractor is to see what their previous clients have to say. Check Google Business and Yelp, along with home-specific sites like HomeAdvisor or Angie's List, make it easier than ever to get a sense of whether or not the individual has a good reputation. In some cases, contractors will even have past clients that have approved a site visit for a similar project so you can see the handiwork in person. Just note that a contractor with a long history of quality work is likely to both book out further and cost more, but you get what you pay for.</p>
<h2>3. Can you provide a detailed written estimate for the project that itemizes labor, materials, and a timeline for completion?</h2>
<p><strong>Why to ask it: </strong>An estimate should always outline the complete scope of the project, so that you can compare estimates from multiple vendors.</p>
<p>A reputable contractor should have no problem providing you with a formal, written estimate with all of the critical details, including work, materials, labor costs, and a timeline for completion. This estimate also helps to provide a baseline for what's expected in your area. Large variances in material costs among estimates, for example, would raise a red flag that you'd want to address before moving forward. While it's helpful to have a quick phone call with your initial short list of prospects, it's recommended you <a href="https://www.thisoldhouse.com/21017791/top-8-pro-tips-on-how-to-hire-a-contractor">get full estimates from three or four</a> once you've narrowed it down.</p>
<h2>4. Do you use written contracts, and can I see a sample contract? What terms and conditions are included?</h2>
<p><strong>Why to ask it: </strong>A project should never get underway without a written contract. This contract should include project details, a payment schedule, warranty information, and dispute resolution procedures should anything go awry.</p>
<p>Much like an estimate, if and when you decide to hire a specific contractor, it's critical that you receive a formal, written contract that solidifies all of the previously scoped details, along with a plan for payment, warranties, and plans for handling a variety of issues that may arise.</p>
<h2>5. What are the local building codes and permit requirements? Will you obtain the necessary permits for the project?</h2>
<p><strong>Why to ask it</strong>: A legitimate contractor should do the legwork for pulling permits — anyone that expects you to do it yourself should raise concern. This is another question that will help you more easily compare multiple contractors.</p>
<p>A contractor should be aware of and prepared to meet specific building/area code and permit requirements before beginning any work, and it shouldn't be up to you to obtain the necessary documentation if you're working with a legitimate professional. You never want to be in a situation where you suddenly realize, halfway through a renovation, that you weren't approved to move forward with it.</p>
<h2>6. How will we communicate throughout the project, and who will be my primary point of contact?</h2>
<p><strong>Why to ask it: </strong>When you work with a contractor, there can be many moving parts (and people). Getting crystal clear on one or two representatives that will be available to answer questions, update you on timelines, and handle issues that arise is essential.</p>
<p>One of the most frustrating parts of a renovation can be the lack of communication. Before it starts, ensure that you can easily reach out to and communicate with the contractor (or someone else close to the project) throughout the process to gain clarity and keep things on track.</p>
<h2>7. Do you use subcontractors, and if so, how do you select them? Can you provide information about their qualifications and references?</h2>
<p><strong>Why to ask it: </strong>The use of subcontractors is common, but it can add another layer of miscommunication and delay your timeline. It can be helpful to learn as much as you can about any that are involved in your renovation ahead of time.</p>
<p>There's typically a necessary order of operations when it comes to subcontractors, so if one is delayed, it may push back the rest of the steps. And if the "drywall guy" your prospective contractor usually hires is tied up, you'll want to know how they plan to vet a new subcontractor before they get started on your project.</p>
<h2>8. Can you provide a payment schedule? How will payments be structured throughout the project?</h2>
<p><strong>Why to ask it:</strong> By insisting on a payment schedule that aligns with clear milestones — i.e. payment one is made only when X has been ordered; payment two is made when X is installed, etc. — you can ensure that the project stays on track and that you're not paying the contractor before significant progress has been made.</p>
<p>It's not uncommon to find yourself in a situation where your contractor is "robbing Peter to pay Paul" — in other words, your upfront payment is being used to fund another project, which can create major delays when it's time to buy the materials for your project if the contractor's next client isn't lined up. You should also be wary of contractors who only accept payment by cash. Payment by check or credit card provides a paper trail, and is generally safer.</p>
<h2>9. Who is responsible for cleanup and disposal of construction debris during and after the project?</h2>
<p><strong>Why to ask it:</strong> A clean work site is a good indication of a good contractor, and makes things safer for all involved.</p>
<p>Cleanup and disposal of debris should be included in the contract. Like permit pulling, your contractor should include detailed information about how construction waste and debris will be removed from your home throughout the project so there aren't any surprises or unwanted materials that can be a nuisance to both you and your neighbors.</p>
<h2>10. How do you handle changes or additions to the project scope, and how are these documented and priced?</h2>
<p><strong>Why to ask it:</strong> Paying for individual changes one at a time can become hard to track, so it's important to stay organized and understand how change orders will be processed, documented, and paid.</p>
<p>When it comes to renovations, expect the unexpected. No matter how well you plan, there will be changes to the project scope — whether it's an issue that's discovered during demolition or that halfway through, you realize you want to add onto the project. Having an understanding of how this works with each particular contractor before they begin is key.</p>
<h2>11. Can you provide any legal protection or guarantees in the contract to ensure the project's success and my satisfaction as a homeowner?</h2>
<p><strong>Why to ask it:</strong> A good contractor values quality work, and their contract should reflect that. It should include protection for incorrect installation, as well as problems that may be discovered after the project's completion, such as peeling paint or warping wood.</p>
<p>You shouldn't move forward with any contractor who refuses to provide any protection or guarantees in the event that issues arise after the project is finished. While they should obviously take pride in their work, they should also want to provide as much reassurance as possible that the work will be completed to your satisfaction — and be willing to remedy it if it doesn't meet reasonable quality standards.</p>
<p>Renovations can be lengthy and complicated, so it pays off to do your homework before deciding on the right person or team to handle it. While a super fast timeline or the lowest cost may seem tempting, these can also be red flags. With a little research and practical advance planning, you can make a more confident decision.</p>`;

export default questionsForContractor;
