import React from 'react';

const InquiryUndeterminedCTAOutcome = ({ isAdditionalTrack }) => {
  return isAdditionalTrack ? (
    <>
      <div className="InquiryOutcomeHeaderContainer">
        <h1>We need a bit more info to get your estimate</h1>
      </div>
      <div className="InquiryOutcomeSubTextContainer">
        Over the next few days, your dedicated Investment Manager will reach out with more details. In the meantime,
        finish your Investment application — it only takes 5 minutes, and we’ll have all the info we need to estimate
        how much equity you can access.
        <div className="InquiryOutcomeEstimateSubText">
          Not sure, yet? Your Investment Manager (and our tools) will help you compare financing options to find one
          that fits your needs.
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="InquiryOutcomeHeaderContainer">
        <h1>We’re getting closer...</h1>
      </div>
      <div className="InquiryOutcomeSubTextContainer">
        We weren’t able to gather all of the information we need to pre-qualify you for an Investment and provide you
        with an Estimate.
      </div>
      <div className="InquiryOutcomeSubTextContainer">
        Sit tight, and your Investment Manager will reach out to you shortly to request the necessary details to help
        you take the next step toward pre-qualifying for a Hometap Investment and finding out how much equity you can
        access.
      </div>
      <div className="InquiryOutcomeSubTextContainer">
        In the meantime, we’ve sent a magic link to your inbox so you can verify your account, so be on the lookout. You
        can also get started on your Application - it takes less than 5 minutes to complete!
      </div>
    </>
  );
};

export default InquiryUndeterminedCTAOutcome;
