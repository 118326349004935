import { useAsync } from '@hometap/htco-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { browserTrack } from 'utils/segment';
import { getVersion } from 'utils/env';
import useSearchParams from 'hooks/useSearchParams';
import { apiWithAuth } from 'utils/api';
import useLocalState from 'apps/htdc/utils/useLocalState';
import { COLOR_ORDER } from '../constants/smartFactConstants';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { useCurrentHome } from 'hooks/useCurrentHome';

const isLessThan24Hours = dateStr1 => {
  if (!dateStr1) {
    return false;
  }
  const date1 = new Date(dateStr1);
  const date2 = new Date();

  // Calculate the difference in milliseconds
  const diff = date2.getTime() - date1.getTime();

  // Check if the difference is more than 24 hours
  return diff < 24 * 60 * 60 * 1000;
};

const fetchSmartFacts = async (requestedSmartFacts = [], excludedSmartFacts = [], smartContexts = []) => {
  return await apiWithAuth.v1.post(`/smart-facts/`, {
    contexts: [...smartContexts],
    amount: 4,
    requested_fact_ids: [...requestedSmartFacts],
    excluded_fact_ids: [...excludedSmartFacts],
  });
};

const useSmartFacts = () => {
  const [smartCardsDate, setSmartCardsDate] = useLocalState(null, 'SmartCardDate');
  const [hasCompletedSmartCards, setHasCompletedSmartCards] = useLocalState(null, 'SmartCardsCompleted');
  const [unhelpfulSmartFacts, setUnhelpfulSmartFacts] = useLocalState([], 'UnhelpfulSmartFacts');
  // Track the previous list of smart facts so we can compare it to what is in the local state
  const [prevUnhelpfulSmartFacts, setPrevUnhelpfulSmartFacts] = useState(unhelpfulSmartFacts);
  const { home } = useCurrentHome();
  const { initialScenario } = useEquityScenarioContext();

  const {
    error: smartFactsError,
    loading: smartFactsLoading,
    execute,
  } = useAsync(fetchSmartFacts, { immediate: false });

  const recentlyViewedSmartFacts = isLessThan24Hours(smartCardsDate);
  const [sessionSmartFacts, setSessionSmartFacts] = useSessionStorage(`SessionSmartFacts-${home?.id}`);

  useEffect(() => {
    if (!home?.id) return;
    const sessionStorageSmartFacts = sessionStorage.getItem(`SessionSmartFacts`);
    if (!sessionStorageSmartFacts || sessionStorageSmartFacts === 'undefined') return;
    setSessionSmartFacts(JSON.parse(sessionStorageSmartFacts));
  }, [home?.id, setSessionSmartFacts]);

  const query = useSearchParams();
  const requestedFactIds = query.get('hometap_insight');

  const unhelpfulSmartFactsChanged = unhelpfulSmartFacts.length !== prevUnhelpfulSmartFacts.length;
  const shouldRefetchSmartFacts = !sessionSmartFacts || !recentlyViewedSmartFacts || unhelpfulSmartFactsChanged;

  const handleCompletion = useCallback(() => {
    if (!hasCompletedSmartCards) {
      browserTrack.smartCardsCompleted({
        version: getVersion(),
      });

      const timeout = setTimeout(() => {
        setHasCompletedSmartCards(true);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [hasCompletedSmartCards, setHasCompletedSmartCards]);

  // Use useEffect to handle the completion state and cleanup
  useEffect(() => {
    if (!hasCompletedSmartCards && !recentlyViewedSmartFacts && !shouldRefetchSmartFacts) {
      return handleCompletion();
    }
  }, [hasCompletedSmartCards, handleCompletion, recentlyViewedSmartFacts, shouldRefetchSmartFacts]);

  const fetchAndStoreSmartFacts = useCallback(
    async ({ homeId, scenarioId, unhelpfulSmartFactsChanged }) => {
      if (smartFactsLoading || !homeId) return;

      const requestedSmartFacts = requestedFactIds?.split(',').map(smartFact => smartFact.trim());

      const contexts = [{ kind: 'system' }, { kind: 'user' }, { kind: 'home', id: homeId }];
      if (scenarioId) {
        contexts.push({ kind: 'equity_scenario', id: scenarioId });
      }

      // If a user has not recently viewed smart facts OR the unhelpful smart facts list has
      // changed, fetch the new smart facts with exclusions
      if (shouldRefetchSmartFacts) {
        const updatedSmartFacts = await execute(requestedSmartFacts, unhelpfulSmartFacts, contexts);

        setHasCompletedSmartCards(false);
        setSessionSmartFacts(updatedSmartFacts);
        setSmartCardsDate(new Date());
        // After updating the smart facts list, update the previous unhelpful smart facts reference
        // with the latest list from our local storage
        if (unhelpfulSmartFactsChanged) {
          setPrevUnhelpfulSmartFacts(unhelpfulSmartFacts);
        }
      }
    },
    [
      smartFactsLoading,
      requestedFactIds,
      shouldRefetchSmartFacts,
      execute,
      unhelpfulSmartFacts,
      setSessionSmartFacts,
      setSmartCardsDate,
      setHasCompletedSmartCards,
    ],
  );

  // Fetch smart facts
  useEffect(
    () => {
      // We want to know if there are new unhelpful smart facts in the local state
      if (!shouldRefetchSmartFacts) return;
      fetchAndStoreSmartFacts({ unhelpfulSmartFactsChanged, homeId: home?.id, scenarioId: initialScenario?.id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldRefetchSmartFacts, home?.id, initialScenario?.id, unhelpfulSmartFactsChanged],
  );

  const smartFacts = useMemo(
    () =>
      (sessionSmartFacts || []).map((smartFact, i) => {
        const hasCta = !!(smartFact?.cta?.text && smartFact?.cta?.url);

        return {
          id: smartFact?.id,
          theme: COLOR_ORDER[i % COLOR_ORDER.length],
          header: smartFact?.fact,
          hasCta: hasCta,
          ctaText: smartFact?.cta?.text,
          onCtaClick: () => window.open(smartFact?.cta?.url, '_blank'), // maybe incorporate react router history
          onHelpful: () => {},
          onUnhelpful: (event, newUnhelpfulFact) => {
            if (!unhelpfulSmartFacts.includes(newUnhelpfulFact)) {
              setUnhelpfulSmartFacts([...unhelpfulSmartFacts, newUnhelpfulFact]);
            }
          },
          ...smartFact,
        };
      }),
    [sessionSmartFacts, setUnhelpfulSmartFacts, unhelpfulSmartFacts],
  );

  // Smart cards viewed segment event logic
  useEffect(() => {
    const initialSmartFactId = smartFacts?.[0]?.id;
    if (initialSmartFactId && !hasCompletedSmartCards) {
      browserTrack.smartCardViewed({
        card_id: initialSmartFactId,
        version: getVersion(),
      });
    }
  }, [hasCompletedSmartCards, smartFacts]);

  return {
    smartFacts,
    smartFactsError,
    smartFactsLoading,
    hasCompletedSmartCards,
    setHasCompletedSmartCards,
    handleCompletion,
    recentlyViewedSmartFacts,
    updateSmartFacts: fetchAndStoreSmartFacts,
  };
};

export default useSmartFacts;
