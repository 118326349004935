import React from 'react';
import cx from 'classnames';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom-v5-compat';

import { Container, Loader, NotFoundBlock, useAsync } from '@hometap/htco-components';

import { getInvestmentDashboardUrl } from 'utils/links';
import { fetchTrackApplicant } from './data/coApplicantRequests';
import SSNVerification from './components/SSNVerification';
import ExceededVerificationAttempts from './components/ExceededVerificationAttempts';
import CoApplicantSummary from './components/CoApplicantSummary';
import CoApplicantTrackDetails from './components/CoApplicantTrackDetails/CoApplicantTrackDetails';
import { LogOutLink } from 'components/LogOutLink/LogOutLink';
import TrackExpiredClosed from 'components/TrackExpiredClosed/TrackExpiredClosed';
import Footer from 'components/Footer/Footer';
import useTrack from 'hooks/useTrack';
import { useCurrentHome, useUserHomes } from 'hooks/useCurrentHome';
import useUserTracksContext from 'hooks/useUserTracksContext';
import TrackSecondaryLayout from 'components/Layouts/SecondaryLayout/TrackSecondaryLayout';
import SecondaryHeader from 'components/Headers/SecondaryHeader/SecondaryHeader';
import './CoApplicant.scss';

const CoApplicantController = () => {
  const { trackId } = useParams();
  const { error, results, loading, execute } = useAsync(fetchTrackApplicant, { immediate: true, params: [trackId] });
  const { track, fetch: fetchTrack, loading: isTrackLoading } = useTrack();
  const { refetch: fetchTracks, loading: isTracksLoading, fetchPendingTracks } = useUserTracksContext();
  const { refetch: refetchUserHomes } = useUserHomes();
  const { isLoading: isHomeLoading } = useCurrentHome();

  const coApplicant = results;

  const errorKey = error?.response?.data?.key;

  const hasConsented = !!coApplicant?.consent_given;
  const isLoading = loading || isTrackLoading || isTracksLoading || isHomeLoading;

  const fetchApplicant = async () => {
    return await execute(trackId);
  };

  const handleVerificationSuccess = async () => {
    // load the track/tracks into memory after the ssn verification is successful.
    // It reloads the user track context to allow the address picker to work correctly.
    return await Promise.all([fetchApplicant(), fetchTrack(), fetchTracks(), refetchUserHomes(), fetchPendingTracks()]);
  };

  const handleConsentSuccess = async () => {
    return await fetchApplicant();
  };

  const trackMembershipDisplay = {
    pending: <SSNVerification trackId={trackId} onSubmitVerificationSuccess={handleVerificationSuccess} />,
    exceeded: <ExceededVerificationAttempts error={error} />,
  };

  if (errorKey === 'CLOSED') {
    return <TrackExpiredClosed />;
  }

  let coApplicantPage;
  const isCoApplicantSumaryPage = coApplicant && !hasConsented;

  if (hasConsented) {
    coApplicantPage = <CoApplicantTrackDetails track={track} />;
  } else if (isCoApplicantSumaryPage) {
    coApplicantPage = <CoApplicantSummary applicant={coApplicant} onConsentSuccess={handleConsentSuccess} />;
  }

  let content;
  if (isLoading) {
    content = <Loader className="CoApplicantLoader" overlay />;
  } else if (error) {
    content = trackMembershipDisplay[errorKey] || <NotFoundBlock error="" buttonLink="/dashboard" />;
  } else {
    content = coApplicantPage;
  }

  return hasConsented || isCoApplicantSumaryPage ? (
    <TrackSecondaryLayout prevUrl={getInvestmentDashboardUrl()}>
      <div className="TrackDetailsControllerContainer PageBodyCenteredContainer">
        <SecondaryHeader label={'Investment ID:'} value={track?.friendly_id} />
        {content}
        <Footer />
      </div>
    </TrackSecondaryLayout>
  ) : (
    <>
      {
        // Non verified co-applicants have limited access and we want to prevent them from getting into
        // flows that would lead to potential errors/confusing experiences.
      }
      <header className="sticky top-0 z-50 flex h-18 w-full items-center border-0 border-b border-solid border-neutral-light-100 bg-white pr-8 md:h-22">
        <LogOutLink className="ml-auto text-lg font-bold" />
      </header>
      <Helmet bodyAttributes={{ class: cx({ isLightBackground: !loading && !coApplicant }) }} />
      <Container className="CoApplicant" align="center" space={5}>
        {content}
      </Container>
    </>
  );
};

export default CoApplicantController;
