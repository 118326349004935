const lifeEventsContent = `<p>After you've accepted a home equity investment with Hometap, we want to make sure you're prepared to manage your Investment through the highs and lows of life. Certain events, like a divorce, death of a spouse, or job loss may create uncertainty when it comes to your Investment.</p>

<p>For example, you may be unsure how or when you'll settle your Investment with us, how to keep us informed of changes to your household, or how to move forward with the initial plans for using your funds. If you're facing one of these scenarios, it's important to know what to expect and the steps you need to take. We've created this short guide to help you better prepare for these unexpected situations.</p>

<h2>Settling Your Hometap Investment</h2>

<p>When experiencing unexpected life events, the most realistic option for some homeowners will be settling their Investment with us. Learn more about the four settlement options available to you by checking out our series of How to Settle blog posts:</p>

<ul>
<li><a href="https://go.hometap.com/dashboard/resources/settling-your-home-equity-investment-with-a-home-sale">Settling with a Home Sale</a></li>
<li><a href="https://go.hometap.com/dashboard/resources/settling-your-home-equity-investment-with-cash-savings">Settling with Cash Savings</a></li>
<li><a href="https://go.hometap.com/dashboard/resources/settling-your-home-equity-investment-with-a-refinance">Settling with a Refinance</a></li>
<li><a href="https://go.hometap.com/dashboard/resources/settling-your-home-equity-investment-with-a-home-loan-or-heloc">Settling with a Home Equity Loan or Line of Credit</a></li>
</ul>

<p>It's also okay if you're not ready to settle yet — we're here to help you get the information you need when it comes to handling your Investment.</p>

<h2>Divorce</h2>

<p>Divorce tends to change homeowners' living situations and often requires the splitting of assets. For many invested homeowners, the home and home equity investment are impactful parts of the divorce process.</p>

<p>In navigating a divorce, some homeowners may choose to settle their Hometap Investment, while others might not be ready to settle but need to address the addition or removal of their ex-spouse from the Investment agreement.</p>

<p>Once you've notified of us, our team will request a copy of the divorce decree to confirm that it's court-ordered. After verifying the decree, we'll collaborate with the title partner to modify the Investment to include or remove your ex-spouse.</p>

<p>In certain states, we don't require a co-applicant when applying for a Hometap Investment, which can result in situations where your ex-spouse isn't listed on your Hometap paperwork but needs to be added due to the divorce. In these cases, our team will guide the other party through a process similar to the initial application steps. Please contact our Investment Support team at <a href="mailto:homeowners@hometap.com">homeowners@hometap.com</a> to get started.</p>

<h2>Passing of a Spouse</h2>

<p>Spouses are often listed on the Investment agreement together. If one spouse passes away, we ask that you send a copy of their death certificate to <a href="mailto:homeowners@hometap.com">homeowners@hometap.com</a> so that our Investment Support team can update our information internally. Once verified, we will make the necessary changes to our servicing system.</p>

<p>If the individual that has passed away was the sole homeowner on the Investment, we ask that the other spouse or the person that is taking over the property contact our team. We'll provide a joinder document to sign in order to be added to the investment.</p>

<h2>Delinquent Payments</h2>

<p>There are many reasons a homeowner may miss a mortgage payment, such as job loss. As an investor in your property, we monitor trade lines, which means that we're notified when a payment is delinquent for 30 days or more. In a situation like this, we'll reach out to you so that we can understand what you may be facing and do our best to help.</p>

<p>When reaching out, we may encourage you to work with your mortgage lender in order to find the solution that is right for you. Some lenders are able to offer mortgage assistance in the form of a loan modification, or introduce you to a forbearance program.</p>

<p>Once you have a plan in place to catch up with your payments, we'd appreciate it if you loop us in. At the same time, we want to remind you that we don't intend to be involved any more than necessary. We're here as a partner throughout the course of your Investment with us, and we're always ready to help. If you ever have any questions, contact our Investment Support team at <a href="mailto:homeowners@hometap.com">homeowners@hometap.com</a>.</p>
`;

export default lifeEventsContent;
