import React from 'react';
import { Button, Icon, useWindowSize } from '@hometap/htco-components';
import { currency } from 'utils/numbers';
import StackedBarGraph from 'components/StackedBarGraph/StackedBarGraph';
import useStackedBarGraph from 'components/StackedBarGraph/useStackedBarGraph';
import HomeGraphHatchOverlay from 'components/HomeGraph/HomeGraphHatchOverlay';
import useAccessHomeEquityCTA from 'apps/dashboard/utils/useAccessHomeEquityCTA';
import HomeValueDisclaimer from '../HomeValueDisclaimer';
import HomeValKeyValue from '../HomeValKeyValue';

const LegendHeader = ({ homeValue, homeValueDisclaimer }) => {
  return (
    <div
      data-testid="legend-header-home-value"
      className="mb-4 flex w-32 flex-row flex-wrap content-center items-center xs:w-full"
    >
      <HomeValKeyValue
        homeValue={homeValue}
        toolTipContent={homeValueDisclaimer}
        toolTipClassName="ml-2 text-sm normal-case"
        toolTipIcon="info"
      />
    </div>
  );
};

const LegendTappableEquityCTA = ({ tappableEquity, openAccessEquityWindow }) => {
  return (
    <div className="ml-8 mt-2" data-testid="legend-tappable-equity-cta">
      <div className="mb-0.5 mr-3">
        <span className="m-0">
          You might be able to tap into <span className="font-bold text-green-dark">{currency(tappableEquity)}</span> of
          your equity
        </span>
      </div>
      <Button theme={'link'} onClick={openAccessEquityWindow}>
        <span>
          Access equity
          <Icon name="arrow-forward" className="ml-2" style={{ fontWeight: 'bold' }} />
        </span>
      </Button>
    </div>
  );
};

const ChartLocked = () => {
  const { isScreenSize } = useWindowSize();
  return (
    <div className={'relative h-full bg-neutral-light-100'}>
      <HomeGraphHatchOverlay height="100%" />
      <Icon
        name="lock"
        size={isScreenSize('xs') ? '2x' : '3x'}
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-neutral-dark-30"
      />
    </div>
  );
};
const StackedEquityGraph = ({ graphData, homeValue, locked, isInvestmentActive, legendDisclaimer }) => {
  const { getSliceData } = useStackedBarGraph(graphData);

  const { showAccessEquityCta, tappableEquity, openAccessEquityWindow } = useAccessHomeEquityCTA(false);
  const showLegendTappableEquityCTA = showAccessEquityCta && !locked;

  const getEquityTooltipContent = () =>
    isInvestmentActive
      ? 'We calculate your current equity by taking the estimated home value, then subtracting Hometap’s Share and the outstanding debt you still have on the property. The data is pulled from your latest home profile details, and this value may change if debts are added or removed from your home profile.'
      : 'We calculate your current equity by taking the estimated home value, then subtracting the outstanding debt you still have on the property. The data is pulled from your latest home profile details, and this value may change if debts are added or removed from your home profile.';

  return (
    <StackedBarGraph>
      <StackedBarGraph.Chart className="w-[50%] min-w-[54px] max-w-[110px] sm:max-w-[275px]">
        {locked ? (
          <ChartLocked />
        ) : (
          <>
            <StackedBarGraph.ChartSlice key="hometapShare" chartKey="hometapShare" {...getSliceData('hometapShare')} />
            <StackedBarGraph.ChartSlice key="equity" chartKey="equity" {...getSliceData('equity')} />
            <StackedBarGraph.ChartSlice key="debt" chartKey="debt" {...getSliceData('debt')} />
          </>
        )}
      </StackedBarGraph.Chart>
      <StackedBarGraph.Legend className="ml-4 mt-2 min-w-[210px] xs:ml-8">
        {/* TODO: PAT-1032 update conditional homeValueDisclaimer */}
        <LegendHeader homeValue={homeValue} homeValueDisclaimer={'Not a lot to say rn'} />
        <StackedBarGraph.LegendSliceTitle
          {...getSliceData('hometapShare')}
          title="Hometap's Share"
          tooltipHeader="Hometap's share"
          tooltipContent={
            'This is the portion of your home’s value that Hometap owns, and we’ll keep when you settle your Investment.'
          }
          tooltipComponent={<Icon name="info" />}
        />
        <StackedBarGraph.LegendSliceValue {...getSliceData('hometapShare')} valueFormatter={currency} />
        <StackedBarGraph.LegendSliceTitle
          {...getSliceData('equity')}
          title="Your Equity"
          tooltipHeader="Current equity"
          tooltipContent={getEquityTooltipContent()}
          tooltipComponent={<Icon name="info" />}
        />
        <StackedBarGraph.LegendSliceValue {...getSliceData('equity')} valueFormatter={currency} />
        {showLegendTappableEquityCTA && (
          <LegendTappableEquityCTA tappableEquity={tappableEquity} openAccessEquityWindow={openAccessEquityWindow} />
        )}
        <StackedBarGraph.LegendSliceTitle
          {...getSliceData('debt')}
          title="Debt"
          tooltipHeader="Debt"
          tooltipContent={
            'Debts on your home may include mortgages, home equity loans, and home equity lines of credit (HELOCs). We’ve pulled this value from your latest home profile details, and it may change if debts are added or removed from your home profile.'
          }
          tooltipComponent={<Icon name="info" />}
        />
        <StackedBarGraph.LegendSliceValue {...getSliceData('debt')} valueFormatter={currency} />
        <div className="mt-4 hidden xs:block">
          <HomeValueDisclaimer disclaimer={legendDisclaimer} />
        </div>
      </StackedBarGraph.Legend>
    </StackedBarGraph>
  );
};
export default StackedEquityGraph;
