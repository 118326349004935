import { Button } from '@hometap/htco-components';
import cx from 'classnames';
import equal_housing_dark from 'images/equal_housing_dark.png';
import equal_housing_light from 'images/equal_housing_light.png';
import './Footer.scss';

const Footer = ({ theme, className }) => {
  const isDark = theme === 'dark';
  const linkTheme = isDark ? 'link-alt' : 'link';

  return (
    <div
      className={cx('Footer', className, {
        isDark: isDark,
        isLight: !isDark,
      })}
      data-testid="page-footer"
    >
      <div className="FooterContainer">
        <div className="FooterCopyrights">
          © {new Date().getFullYear()} Hometap Equity Partners, LLC. All Rights Reserved.
        </div>
        <div className="FooterPrivacy">
          <ul className="FooterPrivacyLinks">
            <li>
              <Button theme={linkTheme} href="https://www.hometap.com/legal?section=terms-of-use" target="_blank">
                Terms of Use
              </Button>
            </li>
            <li>
              <Button theme={linkTheme} href="https://www.hometap.com/legal?section=privacy-policy" target="_blank">
                Privacy Policy
              </Button>
            </li>
            <li>
              <Button theme={linkTheme} href="https://www.hometap.com/legal?section=accessibility" target="_blank">
                Accessibility
              </Button>
            </li>
          </ul>
        </div>
      </div>
      <div className="FooterLegal">
        <div className="FooterContainer">
          <div className="FooterLegalText">
            <div className="FooterNMLS">
              Hometap Equity Partners, LLC.{' '}
              <Button
                className="FooterNMLSLink"
                theme={linkTheme}
                href="https://nmlsconsumeraccess.org/"
                target="_blank"
              >
                NMLS #2467867
              </Button>
            </div>
            <div className="FooterCalifornia">
              Hometap Real Estate Equity Partners, Inc. holds real estate brokerage licenses in certain states.
              California DRE #02191883
            </div>
          </div>
          <img
            className="FooterLegalImage"
            src={isDark ? equal_housing_dark : equal_housing_light}
            alt="equal housing opportunity"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
