import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getNewDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';

import ResourcesTileSection from './ResourcesTileSection';
import {
  FEATURED_RESOURCE,
  FEATURED_RESOURCES_LIST,
  RESOURCE_ARTICLE_TILES,
} from 'apps/dashboard/content/resourceArticles';
import FeaturedResources from './FeaturedResources';
import Footer from 'components/Footer/Footer';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import './ResourcesController.scss';

const ResourcesController = () => {
  return (
    <ScrollToTop>
      <Switch>
        <Route exact path={getNewDashboardUrl(HOMEOWNER_DASHBOARD.RESOURCES)}>
          <PrimaryLayoutGrid className="ResourcesControllerFeaturedResources">
            <div className="col-span-full">
              <h2 className="ResourcesControllerHeader"> Featured resources </h2>
              <FeaturedResources featuredResource={FEATURED_RESOURCE} resourcesList={FEATURED_RESOURCES_LIST} />
            </div>
          </PrimaryLayoutGrid>

          <div className="ResourcesControllerSectionWrapper bg-blue-dark-100">
            <ResourcesTileSection resources={RESOURCE_ARTICLE_TILES} />
            <PrimaryLayoutGrid>
              <Footer theme="dark" className="col-span-full mt-26" />
            </PrimaryLayoutGrid>
          </div>
        </Route>
      </Switch>
    </ScrollToTop>
  );
};

export default ResourcesController;
