import { DOMAINS, HOSTS, TLDS } from './constants/emailDomains';

/**
 * Inspired by code from https://gist.github.com/gf3/761ca1469939057a7fa406bfc229f8ad
 */
// we first need a string distance algorithm
function simpleSift4(s1, s2, maxOffset) {
  if (!s1 || !s1.length) return s2 ? s2.length : 0;
  if (!s2 || !s2.length) return s1.length;

  const l1 = s1.length;
  const l2 = s2.length;

  let c1 = 0; //cursor for string 1
  let c2 = 0; //cursor for string 2
  let lcss = 0; //largest common subsequence
  let local_cs = 0; //local common substring

  while (c1 < l1 && c2 < l2) {
    if (s1.charAt(c1) === s2.charAt(c2)) {
      local_cs++;
    } else {
      lcss += local_cs;
      local_cs = 0;
      if (c1 !== c2) {
        c1 = c2 = Math.max(c1, c2); //using max to bypass the need for computer transpositions ('ab' vs 'ba')
      }
      for (let i = 0; i < maxOffset && (c1 + i < l1 || c2 + i < l2); i++) {
        if (c1 + i < l1 && s1.charAt(c1 + i) === s2.charAt(c2)) {
          c1 += i;
          local_cs++;
          break;
        }
        if (c2 + i < l2 && s1.charAt(c1) === s2.charAt(c2 + i)) {
          c2 += i;
          local_cs++;
          break;
        }
      }
    }
    c1++;
    c2++;
  }
  lcss += local_cs;
  const distance = Math.round(Math.max(l1, l2) - lcss);
  return { distance, lcss };
}

function findClosest(allstrs, s1, threshold = 2) {
  const closestStrings = allstrs.reduce((prev, s2) => {
    const distance = simpleSift4(s1, s2, 5, 13);
    return [...prev, { match: s2, ...distance }];
  }, []);
  const sortedClosestStrings = closestStrings.sort((a, b) => {
    if (a.distance === b.distance) {
      return b.lcss - a.lcss; // sort by lcss descending if distance is the same
    }
    return a.distance - b.distance; // sort by distance ascending
  });
  const [closest] = sortedClosestStrings;

  if (closest && closest.distance > threshold) {
    return undefined;
  }

  return closest;
}

/**
 * Validate e-mail address spelling based on a list of known domains
 * @param {string} email e-mail address to check spelling
 * @returns {string|undefined} returns e-mail suggestion or nothing if no suggestion
 */
export function validateEmailSpelling(email) {
  // get user and domain from address provided
  const match = /(\S+?)@(\S+?)(\.(\S+?))?$/u.exec(email);

  if (!match) {
    return undefined;
  }

  const [, user, host, tld] = match;
  const domain = tld ? `${host}${tld}` : host;

  // Check full domain
  if (DOMAINS.includes(domain)) {
    return undefined;
  }

  if (!host || host.length > 2) {
    const closestDomain = findClosest(DOMAINS, domain);

    if (closestDomain) {
      return `${user}@${closestDomain.match}`;
    }
  }

  // Check host and top-level domains
  if (host && tld) {
    const hostThreshold = host.length > 4 ? 2 : 1;
    const closestHost = host.length > 2 ? findClosest(HOSTS, host, hostThreshold) : undefined;

    const strippedTld = tld[0] === '.' ? tld.slice(1) : tld;
    const tldThreshold = domain.indexOf('.') >= 0 ? 2 : 1;
    const closestTld = findClosest(TLDS, strippedTld, tldThreshold);

    if (!closestHost) {
      if (!closestTld) {
        return undefined;
      }

      const suggest = `${user}@${host}.${closestTld.match}`;

      if (suggest === email) {
        return undefined;
      }

      return suggest;
    }
    const suggestion = !closestTld
      ? `${user}@${closestHost.match}${tld}`
      : `${user}@${closestHost.match}.${closestTld.match}`;

    return suggestion === email ? undefined : suggestion;
  }

  return undefined;
}
