import React from 'react';

import { Grid } from '@hometap/htco-components';
import ResourcesCard from './ResourcesCard';
import PromotionalOfferCard from './promotional-offer-card/PromotionalOfferCard';
import {
  FEATURED_CONTENT_CAROUSEL_SLIDE_LIBERTY_ID,
  FEATURED_CONTENT_CAROUSEL_SLIDE_SIMPLI_SAFE_ID,
} from 'apps/dashboard/constants';
import './ResourcesTileSection.scss';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';

const ResourcesTileSection = ({ resources = [], onPostClick = () => {} }) => {
  return (
    <div className="bg-blue-dark-100">
      <PrimaryLayoutGrid className="ResourceTileLists">
        <div className="col-span-full">
          <div className="ResourcesTileGridWrapper">
            <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 3 }}>
              {resources.map((resource, i) => (
                <Grid xs={12} sm={6} md={6} lg={4} key={`${resource?.title}-${i}`}>
                  {resource?.id === FEATURED_CONTENT_CAROUSEL_SLIDE_LIBERTY_ID ||
                  resource?.id === FEATURED_CONTENT_CAROUSEL_SLIDE_SIMPLI_SAFE_ID ? (
                    <PromotionalOfferCard {...resource} />
                  ) : (
                    <ResourcesCard isLoading={false} onClick={onPostClick} isExternal={false} {...resource} />
                  )}
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </PrimaryLayoutGrid>
    </div>
  );
};

export default ResourcesTileSection;
