import React, { useEffect, useRef, useCallback } from 'react';
import { Modal } from '@hometap/htco-components';
import { useSessionSecurityTimer } from '@hometap/htco-components';

import { IDLE_WARNING_MODAL_CONTAINER } from 'index';

import './IdleWarningModal.scss';
import { getLoginUrl, getPortalsUrl } from 'utils/links';
import { clearStorage } from 'components/LogOutLink/LogOutLink';
import { apiNoAuth } from 'utils/api';

// taken from portals/portals/static/js/session_security_title_flash.js
const NOTIFICATION_TITLE = '(1) Your session is ending';
const TOGGLE_TIME = 1000;
const PING_URL = `${getPortalsUrl()}session_security/ping/`;
// must sync to appropriate settings.py in eng_portals
const SESSION_SECURITY_WARN_AFTER = (23 * 60 * 60 + 50 * 60) * 1000; // 23 hours and 50 minutes in milliseconds
const SESSION_SECURITY_EXPIRE_AFTER = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const IdleWarningModal = () => {
  const fetchIdleStatus = async idleFor => await apiNoAuth(PING_URL, { params: { idleFor } });
  const { shouldWarn, shouldLogOut } = useSessionSecurityTimer(fetchIdleStatus, {
    idleWarnTime: SESSION_SECURITY_WARN_AFTER,
    idleLogoutTime: SESSION_SECURITY_EXPIRE_AFTER,
  });

  const originalTitle = useRef(document.title);
  const toggleRef = useRef(null);

  const toggleTitle = () =>
    (document.title = document.title === originalTitle.current ? NOTIFICATION_TITLE : originalTitle.current);

  const handleTitleIntervals = useCallback(() => {
    if (toggleRef.current) clearInterval(toggleRef.current);
    if (shouldWarn) toggleRef.current = setInterval(toggleTitle, TOGGLE_TIME);
    document.title = originalTitle.current;
  }, [shouldWarn]);

  useEffect(() => {
    handleTitleIntervals();
  }, [shouldWarn, handleTitleIntervals]);

  useEffect(() => {
    const getLoginUrlWithNext = () => {
      const { pathname, search, hash } = window.location;
      return `${getLoginUrl()}?next=${encodeURIComponent(pathname + search + hash)}`;
    };

    if (shouldLogOut) {
      const loginUrl = getLoginUrlWithNext();
      clearStorage();
      window.api.redirect(loginUrl);
    }
  }, [shouldLogOut]);

  return (
    <Modal
      className="IdleWarningModal"
      data-testid="idle-warning-modal"
      onClose={() => {}}
      open={shouldWarn}
      target={document.getElementById(IDLE_WARNING_MODAL_CONTAINER)}
    >
      <div id="session_security_warning" className="session_security" aria-hidden="true" role="dialog">
        <div className="session-security-modal" role="document" tabIndex="-1">
          <h3>Your session is about to expire!</h3>
          <p>Click or type to extend your session.</p>
        </div>
      </div>
    </Modal>
  );
};

export default IdleWarningModal;
