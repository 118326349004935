import { useEffect, useMemo } from 'react';
import HomeEquityHedData from './HomeEquityHedData';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import BuildProfileController from '../dashboard-build-profile/BuildProfileController';
import { useMatch, useNavigate, useLocation, Navigate, Routes, Route as RouteV6 } from 'react-router-dom-v5-compat';
import Footer from 'components/Footer/Footer';

import { HOMEOWNER_DASHBOARD, buildHomeProfileUrl, getNewHEDUrl } from 'utils/links';
import { PROFILE_INSTANCES } from 'apps/dashboard/constants/buildYourProfile';

import { useFlags } from 'launchdarkly-react-client-sdk';
import SmartCardCarousel from './smart-cards/SmartCardCarousel';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import DashboardGoalsPage from 'apps/dashboard/components/dashboard-goals-page/DashboardGoalsPage';
import HouseCanaryLiensController from '../dashboard-house-canary-liens/HouseCanaryLiensController';
import useHouseCanaryLiens from 'apps/dashboard/hooks/useHouseCanaryLiens';
import { getHouseCanaryLienForms } from '../dashboard-house-canary-liens/utils';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import useHedPrequalRange from 'apps/dashboard/hooks/useHedPrequalRange';
import useSmartFacts from 'apps/dashboard/components/home-equity-controller/smart-cards/hooks/useSmartFacts';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper';
import { useHomeValuation, useSelfReportedHomeValuationMutation } from 'apps/dashboard/hooks/useHomeValuation';
import { useCurrentHome } from 'hooks/useCurrentHome';
import useHedInvestmentContext from 'apps/dashboard/hooks/useHedInvestmentContext';
import EquityRenovationController from './equity-renovations/EquityRenovationController';
import FinanceOptionsController from './equity-finance/FinanceOptionsController';
import FinanceGoalsController from '../dashboard-build-profile/finance-goals/FinanceGoalsController';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';

const HomeEquityController = () => {
  const { home, hasHEDAccessToCurrentHome } = useCurrentHome();
  const navigate = useNavigate();
  const { showOverviewPage } = useFlags();
  const { currentScenario, isLoadingHed, initialScenario, chartDataLoading } = useEquityScenarioContext();
  const { investment } = useHedInvestmentContext();
  const { displayedHomeValuation, selfReportedHomeValuation, isLoading: isHomeValuationLoading } = useHomeValuation();

  const location = useLocation();
  const { setupProfileLater } = useDelayHomeEquityProfile();
  const { prequalRange } = useHedPrequalRange();

  const { smartFacts, updateSmartFacts, smartFactsLoading, hasCompletedSmartCards, handleCompletion } = useSmartFacts();

  const hasConfirmedHomeDebts = home && home.hed_debts_confirmed;
  const { fetchAsync: fetchHCLiensAsync } = useHouseCanaryLiens({
    shouldFetch: !hasConfirmedHomeDebts && hasHEDAccessToCurrentHome,
  });

  const homeValueExists = !!displayedHomeValuation?.value;
  const showSmartCards = !showOverviewPage && homeValueExists && smartFacts?.length > 1;

  const HC_FLOW_ENDPOINT = 'accept-liens';
  const HC_FLOW_URL = getNewHEDUrl(`/${HC_FLOW_ENDPOINT}`);
  const matchObject = useMatch(`/dashboard/:currentTab/:page?`);

  const houseCanaryLiensData = fetchHCLiensAsync.results;
  const areScenariosLoading = chartDataLoading || !initialScenario;

  const { mutate } = useSelfReportedHomeValuationMutation(home);

  const houseCanaryLienForms = useMemo(() => {
    return getHouseCanaryLienForms(houseCanaryLiensData?.liens, initialScenario?.liens);
  }, [houseCanaryLiensData?.liens, initialScenario?.liens]);

  const hasRelevantHCLiens = houseCanaryLienForms.length && !areScenariosLoading;
  const shouldRedirectToHouseCanaryFlow =
    homeValueExists &&
    hasRelevantHCLiens &&
    !hasConfirmedHomeDebts &&
    matchObject?.params?.currentTab === HOMEOWNER_DASHBOARD.HOME_EQUITY &&
    matchObject?.params?.page !== HC_FLOW_ENDPOINT &&
    !investment;

  const shouldUpdateSelfReportedHomeValuation =
    selfReportedHomeValuation && !selfReportedHomeValuation.is_editable && selfReportedHomeValuation.valuation?.value;
  useEffect(() => {
    // homeowners can enter the funnel from just being an HED user with a self reported value. If they
    // were to go all the way through the funnel, they would still have a self reported value from
    // potentially years ago. As a product requirement we want to all homeowners to update their self
    // reported value if they're settled. So we need to nullify the value if they have a value but
    // it's not editable. Ideally we'd do this on the backend but that is a larger lift. We also plan
    // on refactoring the way the self reported value is stored in the future. So we can remove this
    // at that time
    if (shouldUpdateSelfReportedHomeValuation) {
      mutate(null);
    }
  }, [mutate, shouldUpdateSelfReportedHomeValuation]);

  useEffect(() => {
    if (shouldRedirectToHouseCanaryFlow) {
      return navigate(HC_FLOW_URL, { replace: true });
    }
  }, [HC_FLOW_URL, navigate, shouldRedirectToHouseCanaryFlow]);

  const isLoading = isLoadingHed || fetchHCLiensAsync.loading || smartFactsLoading || areScenariosLoading;

  const renderDashboard = () => {
    return (
      !isHomeValuationLoading && (
        <>
          {showSmartCards && (
            // Wrapping containers to prevent ResizeObserver issue with Embla
            <div className="h-auto overflow-visible">
              <div className="flex w-full">
                <div className="w-full">
                  <div className="mx-auto my-0 mt-2 w-full overflow-visible border-0 border-b border-solid border-b-neutral-light-100">
                    <PrimaryLayoutGrid>
                      <div className="col-span-full">
                        <SmartCardCarousel
                          cards={smartFacts}
                          handleCompletion={handleCompletion}
                          hasCompleted={hasCompletedSmartCards}
                        />
                      </div>
                    </PrimaryLayoutGrid>
                  </div>
                </div>
              </div>
            </div>
          )}
          <PrimaryLayoutGrid>
            <div className="col-span-full">
              <HomeEquityHedData
                updateSmartFacts={updateSmartFacts}
                currentEquity={currentScenario?.valuations?.currentEquity}
                tappableEquity={prequalRange?.max || 0}
                homeValueMissing={!homeValueExists}
              />
              <Footer theme="light" />
            </div>
          </PrimaryLayoutGrid>
        </>
      )
    );
  };

  return (
    <ScrollToTop>
      <SectionLoadingWrapper loading={isLoading}>
        <Routes>
          <RouteV6
            path={HC_FLOW_ENDPOINT}
            element={
              <PrimaryLayoutGrid>
                <div className="col-span-full">
                  <HouseCanaryLiensController
                    lienForms={houseCanaryLienForms}
                    asOfDate={houseCanaryLiensData?.as_of_date}
                  />
                </div>
              </PrimaryLayoutGrid>
            }
          />
          <RouteV6 path="renovations/:project?/:renovationId?" element={<EquityRenovationController />} />
          <RouteV6
            path="goals"
            element={
              <PrimaryLayoutGrid>
                <DashboardGoalsPage />
              </PrimaryLayoutGrid>
            }
          />
          <RouteV6 path="finance" element={<FinanceOptionsController prequalRange={prequalRange} />} />
          <RouteV6
            path={HOMEOWNER_DASHBOARD.EDIT_PROFILE}
            element={
              home?.hed_debts_confirmed ? (
                <BuildProfileController initialEditConfig={location.state} instance={PROFILE_INSTANCES.EDIT_PROFILE} />
              ) : (
                <Navigate to={`/${HOMEOWNER_DASHBOARD.BUILD_PROFILE}`} />
              )
            }
          />
          <RouteV6 path="explore-financing" element={<FinanceGoalsController initialEditConfig={location.state} />} />
          <RouteV6
            path={HOMEOWNER_DASHBOARD.BUILD_PROFILE}
            element={
              !home?.hed_debts_confirmed || setupProfileLater ? (
                <BuildProfileController initialEditConfig={location.state} instance={PROFILE_INSTANCES.SETUP_PROFILE} />
              ) : (
                <Navigate to={getNewHEDUrl()} />
              )
            }
          />
          <RouteV6
            path="/"
            element={
              home?.hed_debts_confirmed || setupProfileLater ? (
                renderDashboard()
              ) : (
                <Navigate to={buildHomeProfileUrl()} />
              )
            }
          />
        </Routes>
      </SectionLoadingWrapper>
    </ScrollToTop>
  );
};

export default HomeEquityController;
