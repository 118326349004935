import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { NotFoundBlock } from '@hometap/htco-components';
import FAQList from 'components/FAQList/FAQList';
import Footer from 'components/Footer/Footer';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import ModalPageLayoutLite from 'components/Layouts/ModalPageLayoutLite/ModalPageLayoutLite';
import { investmentStatementFAQs } from 'apps/dashboard/constants';
import QuarterlyStatementHeader from './components/QuarterlyStatementHeader';
import useInvestmentStatementPage, { useInvestmentStatementMarkAsViewed } from './hooks/useInvestmentStatementPage';
import QuarterlyStatementContent from './components/QuarterlyStatementContent';
import QuarterlyStatementResourcesSection from './components/QuarterlyStatementResourcesSection';
import { QUARTERLY_STATEMENTS_RESOURCES } from './constants/resources';
import { getYearsBetweenDates } from 'utils/date';
import useShareFractions from 'hooks/useShareFractions';
import { findShareFractionsByYearRange } from 'utils/shareFractions';
import { getAllDocumentsUrl, getNewDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';

const goToAllDocumentsOrDashboard = trackId => {
  if (!trackId) {
    return window.history.back();
  }

  const documentsUrl = getAllDocumentsUrl(trackId);
  if (window.document.referrer?.includes(documentsUrl)) {
    window.location = documentsUrl;
    return;
  }

  window.location = getNewDashboardUrl(HOMEOWNER_DASHBOARD.INVESTMENTS);
};

const QuarterlyStatementController = () => {
  const { investmentId, statementId } = useParams();
  const navigate = useNavigate();
  const { track, isPrimaryUser, statement, applicants, loading } = useInvestmentStatementPage(
    investmentId,
    statementId,
  );

  const { shareFractions } = useShareFractions(
    statement?.investment?.beginning_home_value,
    statement?.investment?.investment_amount,
    statement?.investment?.product_id,
  );

  const shareFractionYear = getYearsBetweenDates(
    statement?.home_valuation?.as_of_date,
    statement?.investment?.effective_date,
    true,
  );

  const shareFraction = findShareFractionsByYearRange(shareFractions, shareFractionYear);

  useInvestmentStatementMarkAsViewed(statement);

  const faqContents = useMemo(
    () =>
      investmentStatementFAQs.map(faq => {
        const faqContext = {
          documentsLink: track ? `/track/${track.id}/documents` : '#',
        };

        return typeof faq.answer === 'function' ? { ...faq, answer: faq.answer(faqContext) } : faq;
      }),
    [track],
  );
  const isNotFound = !loading && !track;

  const shouldRedirectToDocumentsPage = !statement && !loading && track;
  if (shouldRedirectToDocumentsPage) {
    return navigate(`/track/${track?.id}/documents`);
  }

  return (
    <ScrollToTop>
      <ModalPageLayoutLite onModalClose={goToAllDocumentsOrDashboard}>
        {isNotFound ? (
          <NotFoundBlock />
        ) : (
          <SectionLoadingWrapper loading={loading} className="bg-white">
            <div className="PageBodyCenteredContainer pt-10">
              <QuarterlyStatementHeader
                homeAddress={track?.home.address}
                investmentId={track?.friendly_id}
                quarter={statement?.quarter}
                applicants={applicants}
                document={statement?.document}
              />
              <QuarterlyStatementContent
                statement={statement}
                homeAddress={track?.home.address}
                shareFraction={shareFraction}
              />
            </div>
            <div className="PageBodyBottomGradient">
              <div className="PageBodyCenteredContainer">
                <div className="py-20 pb-8 md:py-26 md:pb-14">
                  <FAQList heading="Getting ready to settle?" faqs={faqContents} />
                </div>
                {!isPrimaryUser && <Footer theme="light" />}
              </div>
            </div>
            {isPrimaryUser && (
              <div className="bg-blue-dark-100">
                <PrimaryLayoutGrid>
                  <div className="col-span-full">
                    <QuarterlyStatementResourcesSection
                      title="Additional resources for you"
                      subTitle="Explore calculators, maintenance tips, and more."
                      resources={QUARTERLY_STATEMENTS_RESOURCES}
                      investmentId={statement?.investment.id}
                    />
                    <Footer theme="dark" />
                  </div>
                </PrimaryLayoutGrid>
              </div>
            )}
          </SectionLoadingWrapper>
        )}
      </ModalPageLayoutLite>
    </ScrollToTop>
  );
};

export default QuarterlyStatementController;
