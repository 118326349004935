import useCurrentUser from 'hooks/useCurrentUser';
import { getLoginUrl } from 'utils/links';
import { LogOutLink } from 'components/LogOutLink/LogOutLink';
import { HEADER_NAV_SECTION } from './constants';
import SidebarHeaderNav from './SidebarHeaderNav';
import HeaderNav from './HeaderNav';
import './HeaderNavController.scss';

const HeaderNavController = ({ isDesktopSm, navItems = {} }) => {
  const { user } = useCurrentUser();

  const baseNavItems = {
    [HEADER_NAV_SECTION.ACCOUNT.key]: {
      title: HEADER_NAV_SECTION.ACCOUNT.title,
      items: [
        {
          component: (
            <>
              {!user && <span className="HeaderQuestionText">Already have an account?</span>}
              {user ? <LogOutLink /> : <a href={getLoginUrl()}>Log in</a>}
            </>
          ),
        },
      ],
    },
  };

  const sectionsKeysToConfigure = new Set([...Object.keys(navItems), ...Object.keys(baseNavItems)]);

  // add base nav items and don't show non-visible sections
  const configuredNavItems = [...sectionsKeysToConfigure].reduce((acc, key) => {
    const section = navItems[key] || {};
    const baseSection = baseNavItems[key] || { items: [] };
    const items = [...(section?.items || []), ...baseSection.items];

    acc[key] = section.visible !== false ? { ...baseSection, ...section, items } : {};
    return acc;
  }, {});

  return !!isDesktopSm ? (
    <SidebarHeaderNav configuredNavItems={configuredNavItems} />
  ) : (
    <HeaderNav configuredNavItems={configuredNavItems} />
  );
};

export default HeaderNavController;
