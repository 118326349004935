import React from 'react';
import { Helptip } from '@hometap/htco-components';
import { currency } from 'utils/numbers';

const InquirySuccessfulEstimateOutcome = ({
  upToAmount,
  name,
  isAdditionalTrack,
  isPartnerOutcome,
  investmentAmount,
}) => {
  const renderPartnerSelectedAmount =
    isPartnerOutcome && investmentAmount && investmentAmount > 0 && investmentAmount < upToAmount;

  let outcomeHeaderContent;

  if (upToAmount && name) {
    outcomeHeaderContent = (
      <>
        <span className="InquiryOutcomeEstimateUpToAmountWrapper">
          {name}, you could get up to <span className="InquiryOutcomeEstimateUpToAmount">{currency(upToAmount)} </span>
          from Hometap!{' '}
          <Helptip
            title="How did we calculate this number?"
            text="This amount is based on our current estimated value of your home. It may change once we complete a more detailed valuation analysis of your property and determine how much equity is available in your home."
            className="InquiryOutcomeHelpTip"
            iconColor="#152033"
          />
        </span>
      </>
    );
  } else if (isPartnerOutcome) {
    outcomeHeaderContent = `You’ve been pre-qualified for a Hometap Investment up to ${currency(upToAmount)}!`;
  } else {
    outcomeHeaderContent = 'You are prequalified for a Hometap Investment!';
  }
  return (
    <>
      <div className="InquiryOutcomeEstimateHeaderContainer">
        <h1>{outcomeHeaderContent}</h1>
      </div>

      <div className="InquiryOutcomeEstimateSubTextContainer">
        {renderPartnerSelectedAmount && (
          <h3 className="InquiryOutcomePartnerSubheader">
            Selected Investment amount:
            <span className="InquiryOutcomeInvestmentAmount"> {currency(investmentAmount)}</span>
          </h3>
        )}
        {isAdditionalTrack ? (
          <>
            Over the next few days, your dedicated Investment Manager will reach out with more details. In the meantime,
            finish your Investment application in your account.
          </>
        ) : (
          <>Create an account to save your estimate — and apply for an Investment when you’re ready.</>
        )}
        <div className="InquiryOutcomeEstimateSubText">
          Not sure, yet? Your Investment Manager (and our tools) will help you compare financing options to find one
          that fits your needs.
        </div>
      </div>
    </>
  );
};

export default InquirySuccessfulEstimateOutcome;
