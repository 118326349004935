import first30Image from 'images/dashboard/missing-mortgage-first-30.png';
import last180Image from 'images/dashboard/missing-mortgage-180.png';

const missedMortgagePayments = `<p>When you miss a mortgage payment for any reason, it can be stressful — and you might be wondering what happens if you find yourself in this situation after you've received a Hometap Investment.</p>

<p>As an investor in your home, we want your home value to grow as much as you do, so we keep an eye on events that may impact your home — including the status of your mortgage payments. Hometap monitors tradelines, which are the accounts listed on credit reports. When a credit report notes that a mortgage payment is 30 days late, we're notified and will reach out to you via email.</p>

<p>While we understand that receiving an email like this might seem alarming, we want to assure you that we're reaching out because we want you to know your options as soon as possible and feel confident in your next steps. Below, you'll find a timeline — broken out by days after your missed mortgage payment — so you can better understand what to expect.</p>

<img src="${first30Image}" />

<h2>Day 30</h2>

<p>After we've been informed of your late mortgage payment, we'll reach out to you via email to make sure that we're all on the same page, and go over some of your options.</p>

<p>We'll suggest reaching out to your lender to modify your loan. <a href="https://www.bankrate.com/mortgages/loan-modification-strategy/#how-it-works">Loan modifications</a> can include extending the repayment period, lowering your interest rate, reducing the principal amount, and more. For some invested homeowners, this may be the best way to get their payments back on track.</p>

<p>Another option is to apply for a deferral. <a href="https://www.rocketmortgage.com/learn/forbearance-vs-deferment-mortgage">Mortgage deferments</a> allow you to move your missed payment to the end of your repayment period. If you're experiencing temporary financial hardship and can prove it to your mortgage lender, this may be the best option for you.</p>

<p>Once you and your lender have agreed on any modifications, we ask that you let Hometap know what you've decided to do. You can email us with any updates or questions at <a href="mailto:homeowners@hometap.com">homeowners@hometap.com</a>.</p>

<p>If the situation is resolved in the 30 days before we receive the next tradeline report, there's no further action required on your end.</p>

<p><strong>TIP: Set up autopay</strong> - Setting up automatic payments can be a convenient way to stay on top of your payments. Autopay allows you to make monthly payments towards your mortgage automatically, without the hassle of logging in every month. Visit your lender's website to find more information on how to enroll in this service.</p>

<img src="${last180Image}" />

<h2>Day 60</h2>

<p>After 60 days have passed with no payments, you might start hearing from collections via your lender or a third party. We know it can be stressful to do so, but at this stage, it's important that you reach out to your lender and inquire about loan modifications or other loss mitigation options.</p>

<p><a href="https://www.bankrate.com/mortgages/loss-mitigation/#options">Loss mitigation</a> is a type of relief provided by mortgage lenders that can help homeowners struggling to keep up with their payments.</p>

<h2>Some loss mitigation options include:</h2>

<ul>
<li>
<strong>Forbearance:</strong> This option allows you to briefly reduce or halt your monthly mortgage payments. The unpaid amount is added to your balance and repaid when the forbearance period ends, meaning you'll be faced with higher monthly payments afterwards.
</li>

<li>
<strong>Deferral or partial claim:</strong> Deferrals allow you to pay the unpaid amount at the end of your repayment period. A partial claim also allows you to complete the missed payments, but involves a loan from the U.S. government.
</li>

<li>
<strong>Repayment plan:</strong> You and your lender can work together to create an appropriate repayment plan to make up the missed payments. Often, this includes a higher monthly payment.
</li>

<li>
<strong>Loan modification:</strong> Modifying the terms of your loan agreement is another option, but permanently changes aspects of the original mortgage agreement. Changes may include interest rate and repayment structure.
</li>

<li>
<strong>Reinstatement:</strong> Reinstatement involves paying off all the missed payments in one lump sum. This is a good loss mitigation option for homeowners who have cash on hand.
</li>
</ul>

<p>If you're unsure which loss mitigation option is best for you, or you'd like more information on other assistance programs, you should contact your lender directly. We understand that reaching out can be nerve-racking, so we encourage you to use the sample script we've included below.</p>

<p><em>Dear [Lender's Name],</em></p>

<p><em>I'm writing to inform you that I'm currently experiencing financial difficulties, which is impacting my ability to make timely mortgage payments.</em></p>

<p><em>As I want to ensure that I'm proactive about resolving this situation, I'm reaching out to inquire about the options available to me. Could you please provide information about payment deferral or forbearance programs, loan modification options, repayment plans, or any other assistance programs you may offer?</em></p>

<p><em>I am committed to working together to find a solution and would appreciate your guidance on the next steps. Please let me know if any additional documentation or information is required from me to explore my options.</em></p>

<p><em>Thank you for your understanding and assistance.</em></p>

<p style="margin: 0;"><em>[Your Full Name]</em></p>
<p style="margin: 0;"><em>[Your Address]</em></p>
<p style="margin: 0;"><em>[Your Loan Account Number]</em></p>
<p style="margin: 0;"><em>[Your Phone Number]</em></p>

<h2>Day 90</h2>

<p>If a payment has not been made after 90 days, the mortgage is considered to be in default. At this stage, we'll reach out more frequently than before so we can keep up with what's happening with your home. We want to highlight that it's not necessarily too late to negotiate a payment plan or loan modification with your lender, but these processes are much easier to initiate earlier on.</p>

<h2>Day 120</h2>

<p>After 120 days of delinquent payments, your mortgage lender will most likely start foreclosure proceedings. <a href="https://www.investopedia.com/terms/f/foreclosure.asp">Foreclosure</a> is a process that begins when a homeowner fails to make their mortgage payments, causing the property to be in default. To recover the losses from missed payments, the lender takes ownership of the property and sells it. The timeline for foreclosure often varies by state.</p>

<h2>Day 180+</h2>

<p>Once foreclosure proceedings have started, the lender will either sell the property at auction or repossess the home.</p>

<p>There are many reasons homeowners fall behind on their mortgage payments, and we know that connecting with collections agencies and mortgage lenders can be stressful and scary. We're here to provide you with a proactive approach to getting back on track as soon as possible to reduce stress and keep you in your home.</p>
`;

export default missedMortgagePayments;
