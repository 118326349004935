import React from 'react';
import CardWrapper from 'components/CardWrapper';
import StackedEquityGraph from './StackedEquityGraph';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import useHedInvestmentContext from 'apps/dashboard/hooks/useHedInvestmentContext';
import { useCurrentHome } from 'hooks/useCurrentHome';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper';
import TrackDetailsCardLinks from 'components/TrackDetailsCard/TrackDetailsCardLinks';
import useTrackDetailsCardData from 'components/TrackDetailsCard/useTrackDetailsCardData';
import { STAGES } from 'apps/dashboard/constants';
import FinancialSnapshotTypography from '../FinancialSnapshotTypography';
import HomeValueAsOf from '../HomeValueAsOf';
import HomeValueDisclaimer from '../HomeValueDisclaimer';
import CompleteProfileBanner from '../CompleteProfileBanner';

const StackedEquityGraphLinks = ({ track }) => {
  const { links } = useTrackDetailsCardData(track);
  const trackDetailsLink = { label: 'View details', link: `/track/${track?.id}/` };
  return (
    <TrackDetailsCardLinks
      label={
        <span className="mt-px inline-block">
          Investment ID: <span className="font-bold">{track?.friendly_id}</span>
        </span>
      }
      links={[trackDetailsLink, ...links]}
    />
  );
};

const StackedEquityGraphController = ({ track, homeValuation }) => {
  const { home, loading: homeLoading } = useCurrentHome();
  const { currentScenario, chartDataLoading } = useEquityScenarioContext();
  const { investment, loading: investmentLoading } = useHedInvestmentContext();
  const hasConfirmedHomeDebts = home && home.hed_debts_confirmed;
  const annualAppreciation = currentScenario?.annualAppreciation;
  const isTrackInvested = track?.current_stage === STAGES.INVESTED;
  const showQuickLinks = isTrackInvested && hasConfirmedHomeDebts;
  const isLoading = homeLoading || chartDataLoading || investmentLoading;

  // Get the current year's appreciation data from the scenario. This is to align with data shown in the HED, but we
  // should consider adding some sort of 'current' datasource that would be more precise than yearly data.
  const currentAppreciation = annualAppreciation?.find(x => x.year === new Date().getFullYear());
  const currentHometapShareDollars = currentAppreciation?.hometap_share?.dollars;
  const isInvestmentActive = !!investment && currentHometapShareDollars;

  const getDisclaimer = () => {
    const investedDisclaimer =
      'These values are estimates based on the market and information you provided or confirmed. Keep in mind, this estimate is for informational purposes only — it might differ from the final values at settlement.';
    const nonInvestedDisclaimer =
      'These values are estimates based on the market and information you provided or confirmed. It is for illustrative purposes only and not intended as financial advice.';
    return isInvestmentActive ? investedDisclaimer : nonInvestedDisclaimer;
  };
  const graphData = {
    hometapShare: {
      colorClassName: 'bg-blue-medium-100',
      value: currentHometapShareDollars,
      hidden: !isInvestmentActive,
      empty: !hasConfirmedHomeDebts,
    },
    equity: {
      colorClassName: 'bg-blue-75',
      value: currentScenario?.valuations?.currentEquity,
      empty: !hasConfirmedHomeDebts,
    },
    debt: {
      colorClassName: 'bg-blue-30',
      value: homeValuation?.value - currentScenario?.valuations?.currentEquity - (currentHometapShareDollars ?? 0),
      empty: !hasConfirmedHomeDebts,
      overlay: true,
    },
  };
  return (
    <SectionLoadingWrapper loading={isLoading}>
      <CardWrapper className={'mb-4 pt-5'}>
        <div className="mb-5 flex flex-row justify-between">
          <FinancialSnapshotTypography />
          <div className="hidden xs:block">
            <HomeValueAsOf className="hidden xs:block" date={homeValuation?.as_of_date} />
          </div>
        </div>
        <StackedEquityGraph
          graphData={graphData}
          homeValue={homeValuation?.value}
          locked={!hasConfirmedHomeDebts}
          legendDisclaimer={getDisclaimer()}
        />
        <div className="mt-8 block xs:hidden">
          <HomeValueAsOf date={homeValuation?.as_of_date} />
        </div>
        <div className="mt-3 block xs:hidden">
          <HomeValueDisclaimer disclaimer={getDisclaimer()} />
        </div>
        {showQuickLinks && <StackedEquityGraphLinks track={track} />}
        {!hasConfirmedHomeDebts && <CompleteProfileBanner />}
      </CardWrapper>
    </SectionLoadingWrapper>
  );
};
export default StackedEquityGraphController;
