import React from 'react';
import { Button } from '@hometap/htco-components';

import { getDashboardUrl, getPrimaryAppTrackDetailsUrl } from 'utils/links';

const InquiryCTAOutcomeButton = ({ onClickResendMagicLink, isAdditionalTrack, trackId }) => (
  <>
    {isAdditionalTrack ? (
      <Button
        href={trackId ? getPrimaryAppTrackDetailsUrl(trackId) : getDashboardUrl()}
        className="InquiryOutcomeDashboardButton"
      >
        Get started
      </Button>
    ) : (
      <>
        Didn’t get the link?{' '}
        <Button theme="link" onClick={onClickResendMagicLink}>
          Resend magic link
        </Button>
      </>
    )}
  </>
);

export default InquiryCTAOutcomeButton;
