import React from 'react';
import { MuiTextInput, useForm, Button, Checkbox } from '@hometap/htco-components';
import useCurrentUser from 'hooks/useCurrentUser';
import EmailInputWithValidation from 'components/EmailInputWithValidation/EmailInputWithValidation';
import { browserIdentify, browserTrack } from 'utils/segment';
import { DQ_PAGES, DQ_STEPS } from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';
import './InquiryLocationDQ.scss';

const InquiryLocationDQ = ({ setStepDQ, dqState, dqZipCode }) => {
  const { user } = useCurrentUser();
  const { registerField, handleSubmit, isFormValid, handleFieldChange } = useForm({
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
  });

  const onSubmit = data => {
    const { email, first_name, last_name } = data;
    browserIdentify(data.email, { email, first_name, last_name });
    const segmentEvent = { ...data, state: dqState, zip_code: dqZipCode };
    browserTrack.homeownerInquiryLocationUnavailableFormSubmitted(segmentEvent);
    const url = `${DQ_STEPS[DQ_PAGES.LOCATION_REENGAGEMENT]}`;
    window.history.pushState(null, DQ_STEPS.LOCATION, url);
    setStepDQ(DQ_PAGES.LOCATION_REENGAGEMENT);
  };

  const redirectToHEDReengagement = () => {
    const url = `${DQ_STEPS[DQ_PAGES.LOCATION_REENGAGEMENT_HED]}`;
    window.history.pushState(null, DQ_STEPS.LOCATION, url);
    setStepDQ(DQ_PAGES.LOCATION_REENGAGEMENT_HED);
  };

  return (
    <div className="InquiryLocationDQ">
      <h1 className="DQHeader" data-testid="dq-header">
        We're not making Investments in your area (right now)
      </h1>
      <p className="InquiryDQBody" data-testid="inquiry-dq-row-1">
        But let’s keep in touch! Our team is working hard to get up and running in your area, and we'll let you know as
        soon as we're there.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="InquiryLocationDQForm">
          <MuiTextInput required label="First Name" maxLength={30} width="100%" {...registerField('first_name')} />
          <MuiTextInput required label="Last Name" maxLength={30} width="100%" {...registerField('last_name')} />
        </div>
        <EmailInputWithValidation
          required
          label="Email Address"
          {...registerField('email')}
          handleFieldChange={handleFieldChange}
        />
        <div className="InquiryLocationDQConsentCheckbox">
          <Checkbox
            label="I agree to be notified when Hometap is available in my state"
            required
            {...registerField('consent', 'checked')}
          />
        </div>
        <div className="InquiryLocationDQButtons">
          <Button data-testid="location-dq-notify-me" type="submit" width="100%" disabled={!isFormValid}>
            Notify me
          </Button>
          <Button
            data-testid="location-dq-opt-out"
            theme="secondary"
            width="100%"
            onClick={() => redirectToHEDReengagement()}
          >
            No thanks
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InquiryLocationDQ;
