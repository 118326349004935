const homeMaintenanceMistakes = `<p>Nobody knows about the consequences of skipping home maintenance more than home inspectors. As homes age, the likelihood of problems naturally increases. However, there are more than a few ways to lessen the chances of major issues through some fairly simple ongoing tasks. Here are some of the most common pitfalls home inspectors observe, and how you can prevent or reduce their negative impact to preserve your home's value.</p>

<h2>1. Improper grading and/or drainage issues</h2>
<p><em>Why it's a problem:</em></p>
<p>If it sounds complicated, that's because it is. But If water doesn't have a clear path to take away from your home when it rains, it can spell big trouble. These mistakes can lead to cracked foundations, flooded basements or main floors, rot, mold, and even larger issues that can impact a home's structural integrity if not addressed.</p>

<p><em>Potential solutions:</em></p>
<ul>
  <li>Building up low spots in your yard</li>
  <li>Aerating your lawn (annually)</li>
  <li>Installing a french drain</li>
  <li>Cleaning out gutters and downspouts (biannually)</li>
</ul>

<p><em>DIY or hire a professional:</em></p>
<p>That depends on the issue. There are some issues — like building up low spots and clearing out gutters and downspouts that you can stay on top of yourself quite easily. However, bigger tasks like lawn aeration, French drain installation, mold remediation, and flooded basement repair are best left to the professionals.</p>

<p><em>Average cost range:</em></p>
<p>Lawn aeration: <a href="https://homeguide.com/costs/lawn-aeration-cost">$75 to $250</a></p>
<p>Foundation repair: <a href="https://www.thisoldhouse.com/foundations/reviews/foundation-repair-cost">$2,000–$7,000</a></p>
<p>Mold remediation: <a href="https://www.thisoldhouse.com/foundations/reviews/mold-remediation-cost">$500–$4,000</a></p>
<p>French drain installation: <a href="https://www.bobvila.com/articles/french-drain-cost/">$5,000</a></p>
<p>Flooded basement repair: <a href="https://www.homeadvisor.com/cost/disaster-recovery/clean-a-flooded-basement/">$4,000</a></p>

<h2>2. Pest damage</h2>
<p><em>Why it's a problem:</em></p>
<p>Uninvited houseguests are bad enough, but ones of the non-human variety are even worse. Depending on the type of pest, an insect (e.g. termites, ants, or roaches) or rodent infestation can cause major structural damage to your home, in addition to being a nuisance to you and your family.</p>

<p><em>Potential solutions:</em></p>
<ul>
  <li>DIY prevention and extermination methods, including traps, sprays for treated wood, food source/entry point elimination, and nontoxic solutions or compounds (ongoing or as needed)</li>
  <li>Professional preventative pest control and/or extermination if the infestation is severe</li>
</ul>

<p style="margin-bottom: 0;"><em>DIY or hire a professional:</em></p>
<p style="margin-top: 0;">For small and seasonal issues, like ants in the summertime, there are home remedies, fairly easy fixes, and store-bought traps or solutions that can eliminate the pests. However, if the infestation has progressed to the point where it's damaging your home or affecting your quality of life, you'll need to hire an exterminator and possibly pay for ongoing pest control even after the pest is eradicated.</p>

<p><em>Average cost range:</em></p>
<ul>
  <li>One-time extermination visit: <a href="https://www.thisoldhouse.com/pest-control/reviews/pest-control-cost">$100–$600</a></li>
  <li>Annual pest control plan: <a href="https://homeguide.com/costs/pest-control-prices">$300–$900</a></li>
</ul>

<h2>3. Not keeping up with roof repairs</h2>
<p><em>Why it's a problem:</em></p>
<p>Aside from the obvious protection a roof provides from the elements, it's one of the most important features that buyers look at when considering whether to purchase a home — and can even make or break the sale. If you've let your roof go too long without proper maintenance, you might end up needing to replace it, which can be extremely expensive.</p>

<p><em>Potential solutions:</em></p>
<ul>
  <li>Preventative maintenance: clean out gutters and downspouts, regularly inspect shingles and flashing, and check for algae and mildew, watch out for ice dams</li>
  <li>Roof repair or replacement</li>
</ul>

<p><em>DIY or hire a professional:</em></p>
<p>You may be able to stay on top of preventative maintenance tasks, like gutter cleaning, on your own, but it's likely much safer to hire a professional to help. And you should entrust any repair or replacement to them as well in order to get the job done correctly and cost-effectively.</p>

<p style="margin-bottom: 0;"><em>Average cost range:</em></p>
<p>Repair: <a href="https://www.thisoldhouse.com/roofing/reviews/cost-of-roof-repair">$1,067</a></p>
<p>Partial to full replacement: <a href="https://www.thisoldhouse.com/roofing/reviews/cost-of-roof-repair">$2,000–$8,000</a></p>

<h2>4. Plumbing problems</h2>
<p><em>Why it's a problem:</em></p>
<p>Obviously, functioning toilets, sinks, and showers are essential to day-to-day life. But clogs or leaks can lead to major problems if left unaddressed, and the latter can even cause significant damage to your ceilings, walls, and floors. Another common issue is a malfunctioning hot water heater, which can be expensive if replacement is necessary.</p>

<p><em>Potential solutions:</em></p>
<ul>
  <li>Clearing drain clogs</li>
  <li>Repairing water lines/pipe leaks</li>
  <li>Repairing toilet</li>
  <li>Repairing/installing water heater</li>
</ul>

<p><em>DIY or hire a professional:</em></p>
<p>Plumbing is one area where it almost always pays to hire a professional. Unless you're an experienced plumber, attempting to delve into your home's toilets, sinks, drains, and pipes can be a very slippery slope that can end up saddling you with even higher costs to fix your mistakes if something goes awry.</p>

<p><em>Average cost range:</em></p>
<p>Leaking pipe repair: <a href="https://www.thisoldhouse.com/plumbing/reviews/plumbing-cost">$150–$400</a></p>
<p>Main water line leak repair: <a href="https://www.thisoldhouse.com/plumbing/reviews/plumbing-cost">$500–$4,000</a></p>
<p>Toilet repair: <a href="https://www.thisoldhouse.com/plumbing/reviews/plumbing-cost">$100–$300</a></p>
<p>Water heater repair: <a href="https://www.thisoldhouse.com/plumbing/reviews/plumbing-cost">$150–$750</a></p>
<p>Water heater installation: <a href="https://www.thisoldhouse.com/plumbing/reviews/plumbing-cost">$800–$2,000</a></p>

<h2>5. Electrical wiring that's not up to code</h2>
<p><em>Why it's a problem:</em></p>
<p>Not only will faulty wiring raise red flags for home inspectors, but it can also be a major fire hazard and prevent new homebuyers from securing homeowners insurance for the property.</p>

<p><em>Potential solutions:</em></p>
<ul>
  <li>Ground fault circuit interrupter (GFCI) outlet installation for moist areas like kitchens and bathrooms</li>
  <li>Partial or full rewiring of the home</li>
</ul>

<p><em>DIY or hire a professional:</em></p>
<p>Like plumbing, you'll want to entrust rewiring to a certified electrician to avoid mistakes that become more expensive to fix than the original issue.</p>

<p style="margin-bottom: 0;"><em>Average cost range:</em></p>
<p>GFCI installation: <a href="https://www.bobvila.com/articles/gfci-outlet-cost/">$130–$300</a></p>
<p>Rewiring: <a href="https://www.bankrate.com/homeownership/cost-to-rewire-house/#cost-to-rewire">$10,000–$30,000</a></p>

<h2>6. HVAC issues</h2>
<p><em>Why it's a problem:</em></p>

<p>Functioning heating and cooling systems are essential for any homeowner. Beyond personal comfort, improperly cleaned and maintained systems can foster mold and bacteria buildup or become contaminated, causing respiratory and breathing problems, especially in those with asthma.</p>

<p><em>Potential solutions:</em></p>
<ul>
  <li>Regular tune-ups and cleanings</li>
  <li>System repair or replacement</li>
</ul>

<p><em>DIY or hire a professional:</em></p>
<p>While you can stay up to date with maintenance tasks like keeping your system clear of dust and debris and regularly cleaning filters, any repair or installation project should be left to the professionals for your best chance of a quick and successful repair.</p>

<p><em>Average cost range:</em></p>
<ul>
  <li>Tune-up: <a href="https://www.thisoldhouse.com/heating-cooling/reviews/hvac-maintenance-cost">$75–$200</a></li>
  <li>Repairs: <a href="https://www.thisoldhouse.com/heating-cooling/reviews/hvac-maintenance-cost">$2,000–$3,000</a></li>
</ul>

<p>If you're currently dealing with any of these issues — or just want to avoid them in the future — and could benefit from some cash, consider seeing how much equity you can tap into with a <a href="https://go.hometap.com/inquiry/info/property/">home equity investment</a>.</p>`;

export default homeMaintenanceMistakes;
