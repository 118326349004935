import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  TestimonialsPanelCarousel,
  MuiTextInput,
  MuiSelect,
  useWindowSize,
  useAsync,
  SlideDown,
} from '@hometap/htco-components';
import { ProgressiveUITemplate, CallToAction, ContentPanel } from './ProgressiveUITemplate';
import PreparingEstimateModal from './PreparingEstimateModal';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  PROGRESSIVE_FORM_FIELDS,
  PROGRESSIVE_TESTIMONIALS_CAROUSEL_CARDS,
  PROGRESSIVE_TELL_US_MORE_FIELDS,
  PAGE_TITLES,
  PROGRESSIVE_STEP_NAMES,
  CONTINUE_BUTTON_ID,
  PREQUAL_OUTCOME,
  DQ_PAGES,
  EMAIL_ERROR_MESSAGES,
} from '../constants/progressiveInquiryConstants';
import ProgressiveInquiryConsent from 'components/ProgressiveInquiryConsent/ProgressiveInquiryConsent';
import useConfigurationOptions from 'hooks/useConfigurationOptions';
import { phoneValidator } from 'utils/validators';
import { getLoginUrl } from 'utils/links';
import { showNotification } from 'utils/toasts';
import { convertInquiry } from 'apps/progressive-inquiry/api/inquiryRequests';
import { FICO_SCORE_TYPES, PREQUAL_TYPES, RISK_BAND_TYPES } from 'apps/progressive-inquiry/constants/APIConstants';
import {
  parsePrequalificationsToMap,
  parseRiskPrequalOutcomeDetails,
} from 'apps/progressive-inquiry/utils/parsePrequalificationOutcomes';
import { sendPasswordInitializationLink } from 'apps/dashboard/data/sendPasswordInitializationLink';
import { FORM_FIELDS } from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';
import EmailInputWithValidation from 'components/EmailInputWithValidation/EmailInputWithValidation';
import { browserTrack } from 'utils/segment';
import useCTAChangedNotification from 'hooks/useCTAChangedNotification';
import usePrev from 'hooks/usePrev';
import { useAsyncPatchInquiry } from '../hooks/inquiryMutations';
import './InquiryCreate.scss';

const SCREEN_BREAKPOINT_TABLET = 900;

const InputWrapper = ({ children }) => <div className="InputWrapper">{children}</div>;

const InquiryCreate = ({ isLoggedIn, inquiryId, form, onDequalify, onQualify }) => {
  const { registerField, isFormValid, formData, setErrors, handleFieldChange, handleSubmit } = form;

  const [showModal, setShowModal] = useState(false);
  const [outcomeCode, setOutcomeCode] = useState();
  const [maxAmount, setMaxAmount] = useState();

  const { screenWidth } = useWindowSize();
  const doShowImageContent = screenWidth > SCREEN_BREAKPOINT_TABLET;
  const { dropdownConfigurationOptions } = useConfigurationOptions({
    names: ['how_heard_options'],
  });
  const flags = useFlags();

  const passwordInitEnabled = !flags?.showInquirySignInMethods && !isLoggedIn;

  const handlePrequalifications = patchResults => {
    const { prequalifications } = patchResults;
    const prequalMap = parsePrequalificationsToMap(prequalifications);
    const maxInvestmentAmount = prequalMap?.maxAmount;
    const riskFailFactors = prequalMap?.riskFailFactors;
    const ficoScore = prequalMap?.ficoScore;
    const experianDataPulledAt = prequalMap?.experianDataPulledAt;
    const { InvestmentAmountPrequalification } = prequalMap?.prequalifications;
    const { outcome_details, outcome_code } = InvestmentAmountPrequalification;
    setOutcomeCode(outcome_code);
    setMaxAmount(maxInvestmentAmount);

    if (outcome_code !== PREQUAL_OUTCOME.FAIL) return true;

    const prequalDetails = parseRiskPrequalOutcomeDetails(outcome_details);
    const { risk_band, fico_score } = prequalDetails;
    if (risk_band === RISK_BAND_TYPES.FAIL) {
      onDequalify(DQ_PAGES.RISK, { experianDataPulledAt });
      return false;
    }
    if (fico_score === FICO_SCORE_TYPES.FAIL) {
      onDequalify(DQ_PAGES.FICO, { riskFailFactors, ficoScore, experianDataPulledAt });
      return false;
    }
    return true;
  };

  const handleSuccessfulPatch = async patchResults => {
    // Route the homeowner to the appropriate DQ page if necessary
    const isQualified = handlePrequalifications(patchResults);
    if (isQualified) {
      // Execute the second call to convert the inquiry
      await createInquiry(inquiryId, { email: formData.email });
    }
  };

  const handleUnsuccessfulPatch = patchError => {
    setShowModal(false);
    const errorCode = patchError.response?.data?.email?.[0];
    setErrors(PROGRESSIVE_FORM_FIELDS.EMAIL, errorCode, false);
  };

  // Once the /convert call succeeds, this will execute the final call to /send-password-initialization-link before
  // progressing to the next page
  const handleSuccessfulConvert = async () => {
    if (passwordInitEnabled) {
      await initPassword(formData.email);
    }
  };

  const handleUnsuccessfulConvert = () => {
    setShowModal(false);
    showNotification({
      type: 'error',
      title: 'Failed to submit inquiry',
      description: 'Failed to submit inquiry. Please retry',
    });
  };

  const handleUnsuccessfulPasswordInit = () => {
    setShowModal(false);
    showNotification({
      type: 'error',
      title: 'Failed to send email',
      description: 'Failed to send magic link to email. Please retry.',
    });
  };

  const onSubmit = () => {
    const {
      first_name,
      last_name,
      email,
      phone_number,
      consent_given,
      primary_use_case,
      primary_use_case_choice_details,
      how_heard,
      how_heard_choice_details,
    } = formData;

    // Execute the first call to PATCH the inquiry
    handleSubmit(async () => {
      setShowModal(true);

      await updateInquiry(
        inquiryId,
        {
          first_name,
          last_name,
          email,
          phone_number,
          consent_given,
          primary_use_case,
          primary_use_case_choice_details,
          how_heard,
          how_heard_choice_details,
        },
        [PREQUAL_TYPES.INVESTMENT_AMOUNT, PREQUAL_TYPES.RISK],
      );
    })();
  };

  const {
    results: convertResults,
    execute: createInquiry,
    loading: convertLoading,
  } = useAsync(convertInquiry, {
    onSuccess: handleSuccessfulConvert,
    onError: handleUnsuccessfulConvert,
  });

  const {
    error: patchError,
    loading: patchLoading,
    execute: updateInquiry,
  } = useAsyncPatchInquiry('Error patching inquiry in inquiry create', {
    onSuccess: handleSuccessfulPatch,
    onError: handleUnsuccessfulPatch,
  });

  const {
    results: initPasswordResults,
    execute: initPassword,
    loading: initPasswordLoading,
  } = useAsync(sendPasswordInitializationLink, {
    onError: handleUnsuccessfulPasswordInit,
  });

  const errorCode = patchError?.response?.data?.email?.[0];
  const prevError = usePrev(errorCode);
  useEffect(() => {
    // If the error code changes, or if we have an error without an error code, report the error shown.
    if (patchError && (prevError !== errorCode || errorCode === undefined)) {
      if (errorCode === EMAIL_ERROR_MESSAGES.IN_USE.key) {
        browserTrack.errorShown({ error: EMAIL_ERROR_MESSAGES.IN_USE.label, field: 'email' });
      } else if (errorCode === EMAIL_ERROR_MESSAGES.MUST_MATCH_SESSION.key) {
        browserTrack.errorShown({ error: EMAIL_ERROR_MESSAGES.MUST_MATCH_SESSION.label, field: 'email' });
      } else {
        browserTrack.errorShown({ error: 'Server Error', field: 'inquiry_create' });
      }
    }
  }, [patchError, prevError, errorCode]);

  const renderErrorMessage = () => {
    const errorCode = patchError?.response?.data?.email?.[0];

    const EmailInUseError = () => (
      <span>
        {EMAIL_ERROR_MESSAGES.IN_USE.label} <a href={getLoginUrl()}>{EMAIL_ERROR_MESSAGES.IN_USE.loginLabel}</a>
      </span>
    );

    const EmailMustMatchSessionError = () => (
      <span>
        {EMAIL_ERROR_MESSAGES.MUST_MATCH_SESSION.label}{' '}
        <a href={getLoginUrl()}>{EMAIL_ERROR_MESSAGES.MUST_MATCH_SESSION.loginLabel}</a>
      </span>
    );

    const ServerError = () => <span>We are unable to reach the server, please try again.</span>;

    if (errorCode === EMAIL_ERROR_MESSAGES.IN_USE.key) {
      return <EmailInUseError />;
    } else if (errorCode === EMAIL_ERROR_MESSAGES.MUST_MATCH_SESSION.key) {
      return <EmailMustMatchSessionError />;
    }
    return <ServerError />;
  };

  const showTellUsMoreField = PROGRESSIVE_TELL_US_MORE_FIELDS.includes(formData?.[PROGRESSIVE_FORM_FIELDS.HOW_HEARD]);

  const isLoading = patchLoading || convertLoading || initPasswordLoading;

  const continueDisabled = !isFormValid || isLoading || !formData[FORM_FIELDS.CONSENT_GIVEN];

  const ctaText = 'Continue to my estimate';
  useCTAChangedNotification(ctaText, !continueDisabled);

  const loadedData = passwordInitEnabled ? initPasswordResults : convertResults;

  return (
    <div className="InquiryCreate" data-testid="inquiry_homeowner_contact_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.CONTACT]} />
      <ProgressiveUITemplate>
        <ContentPanel>
          <h1>Just one more step...</h1>
          <InputWrapper>
            <EmailInputWithValidation
              name={PROGRESSIVE_FORM_FIELDS.EMAIL}
              label="Email Address"
              width="100%"
              required
              disabled={isLoggedIn && formData?.email}
              handleFieldChange={handleFieldChange}
              {...registerField(PROGRESSIVE_FORM_FIELDS.EMAIL)}
            />
          </InputWrapper>
          <InputWrapper>
            <MuiTextInput
              name="phone_number"
              label="Phone Number"
              type="tel"
              inputMode="tel"
              mask="000-000-0000"
              unmask={false}
              maxLength={14}
              validator={phoneValidator}
              width="100%"
              required
              {...registerField(PROGRESSIVE_FORM_FIELDS.PHONE_NUMBER)}
            />
          </InputWrapper>
          <InputWrapper>
            <MuiSelect
              required
              name="how_heard"
              label="How did you hear about us?"
              width="100%"
              options={dropdownConfigurationOptions?.how_heard_options}
              {...registerField(PROGRESSIVE_FORM_FIELDS.HOW_HEARD)}
            />
          </InputWrapper>
          {showTellUsMoreField && (
            <InputWrapper>
              <MuiTextInput
                label="Tell us more (Optional)"
                width="100%"
                {...registerField(PROGRESSIVE_FORM_FIELDS.HOW_HEARD_DETAILS)}
              />
            </InputWrapper>
          )}
          <div className="Consent">
            <ProgressiveInquiryConsent registerField={registerField} />
          </div>
          <div className="InquiryError">
            <SlideDown visible={!!patchError}>
              <ErrorMessage>{patchError && renderErrorMessage()}</ErrorMessage>
            </SlideDown>
          </div>
          <CallToAction>
            <Button id={CONTINUE_BUTTON_ID} disabled={continueDisabled} onClick={onSubmit} loading={isLoading}>
              {ctaText}
            </Button>
          </CallToAction>
        </ContentPanel>
        {doShowImageContent && (
          <ContentPanel containsVisual greyTheme>
            <TestimonialsPanelCarousel
              className="InquiryCreateTestimonial"
              title="WHAT PEOPLE ARE SAYING"
              cards={PROGRESSIVE_TESTIMONIALS_CAROUSEL_CARDS}
            />
          </ContentPanel>
        )}
      </ProgressiveUITemplate>
      {showModal && (
        <PreparingEstimateModal
          data={loadedData}
          onFinish={() => {
            const trackId = convertResults?.track_id;
            onQualify(outcomeCode, maxAmount, trackId);
          }}
        />
      )}
    </div>
  );
};

export default InquiryCreate;
